import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SignupForm from '../components/signupForm';

const Subscribe = ({ location }) => {
  return (
    <Layout location={location} title="Subscribe | Spreadsheets Made Easy">
      <SEO title="Subscribe" />
      <article>
        <header><h1 style={{ marginTop: 0 }}>Subscribe to our Mailing List!</h1></header>
        <section>
          <SignupForm />
          <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src="/images/subscribe.png" alt="Subscribe" />
        </section>
      </article>
    </Layout>
  )
}

export default Subscribe
